.ErrorDisplay {
  color: red;
  font-size: 32px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.LoadingDisplay {
  color: #f4f4f4;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.LoadingDisplay p {
  font-size: 32px;
  margin-right: 20px;
}

.LoadingDisplaySpinner {
  font-size: 28px;
}

.PlotContainer {
	text-align: center;
	margin: 20px;
	margin-top: 80px;
}

.PlotContainerHeader {
  color: white;
  font-size: 32px;
  text-decoration: underline;
  font-weight: bold;
}

.PlotLabel {
	color: white;
	font-weight: bold;
	font-size: 22px;
}

.PlotContainer img {
	width: 100%;
	max-width: 1200px;
}

.NavBarContainer {
	width: 100%;
	height: 40px;
	padding: 10px;
	background-color: #2b2b2b;
	border-bottom: 2px solid #1C549A;
	position: fixed;
	top: 0px;
}


/* Links inside the navbar */
.NavBarContainer a {
  float: left;
  font-size: 16px;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  color: #00BD70;
}

/* The dropdown container */
.DropdownContainer {
  float: left;
  overflow: hidden;
}

/* Dropdown button */
.DropdownContainer .dropbtn {

  font-size: 16px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit; /* Important for vertical align on mobile phones */
  margin: 0; /* Important for vertical align on mobile phones */
}

/* Add a red background color to navbar links on hover */
.NavBarContainer a:hover, .DropdownContainer:hover .dropbtn {
  background-color: #222222;
}

/* Dropdown content (hidden by default) */
.DropdownContent {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.DropdownContent a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.DropdownContent p {
  font-weight: bold;
  color: black;
  text-align: center;
  margin: 0px;
  text-decoration: underline;
}
/* Add a grey background color to dropdown links on hover */
.DropdownContent a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu on hover */
.DropdownContainer:hover .DropdownContent {
  display: block;
}

.DateAdvanceDiv {
  float: right;
  margin-right: 10px;
  align-items: center;
  height: 100%;
  display: flex;
  text-align: center;
  align-content: center;
}

.DateAdvanceButton {
    float: right;
    color: #0F1A2E;
    font-weight: bold;
    background: #00BD70;
    cursor: pointer;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border: none;
    border-radius: 5px;
    /* border: 2px solid #1C549A; */
    font-size: 14px;
    /* margin-top: 30px; */
    transition: all 200ms;
    margin-right: 10px;
}

.DateLabel {
  text-align: left;
  color: white;
  font-size: 22px;
  text-decoration: underline;
}

.DateAdvanceDiv p {
  color: white;
  cursor: pointer;
  font-weight: bold;
  display: inline-block;
  margin-right: 20px;
}

.MissingPlotIcon {
  color: red;
  font-size: 32px;
}