.LoadingOverlayBG {
	width: 100%;
	height: 100%;
	z-index: 9999;
}

.LoadingOverlayContainer {
  width: 300px;
  height: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -150px 0 0 -150px;
}

.LoadingOverlaySpinner {
	color: #f4f4f4;
}

.LoadingOverlayLabel {
	font-size: 28px;
	color: #f4f4f4;
}
