.FieldMetricsTableContainer {
	margin: 20px;
	background: #e5e5ec;
	border-radius: 5px;
    padding: 10px;
}

.FieldMetricsTable {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    padding: 10px;
    border: 2px solid black;
}

.FieldMetricsTable td {
  height: 10px;
  border-left: 1px dotted #2b2b2b;
/*  padding: 5px;*/
}

.FieldMetricsTable thead  {
    border-right: 1px solid black;
    font-family: 'Oswald', Tahoma, sans-serif!important;

}

.FieldMetricsTable thead th {
/*    border-bottom: 1px solid #2b2b2b;*/
    background: #e5e5ec;
}

.FieldMetricsTable tr {
/*    border-bottom: 1px solid #2b2b2b;*/
    background: #f3f3f7;
}


.FieldMetricsTable tbody {
    border: 2px solid #2b2b2b;
}

.FieldMetricsTable td:nth-child(n+2) {
  border-bottom: 1px dotted #2b2b2b;
  padding: 0px;
}

.FieldMetricsTable td:nth-child(1) {
  border-bottom: 1px solid #2b2b2b;
}

.FieldMetricsTableVarLabel {
    font-family: 'Oswald', Tahoma, sans-serif!important;
    text-decoration: underline;
    font-size: 24px;
    margin: 10px;
    float: left;
}

.FieldMetricsTableDSLabel {
      font-family: 'Oswald', Tahoma, sans-serif!important;
    font-size: 18px;
    font-weight: bold;
    float: right;
    margin: 10px;
}

.FieldMetricsTableHeaderLabelCell {
    border: 1px solid black;
}

.FieldMetricsTableRowLabel {

}

.FieldMetricsTableCell {
    text-align: center;
}

.FieldMetricsTableRowLabel:hover .horizTooltip {
    display: inline-block;
}

.horizTooltip {
    margin: auto;
    display: none;
    color: white;
    background: black;
    border-radius: 2px;
    padding: 2px;
    margin-left: 20px;
    position: absolute;
    z-index: 1000;
}

.FieldMetricsTableCell:hover .tooltip {
  display:inline-block;
}

.tooltip {
    margin:0 auto;
    display: none;
    color: white;
    background: black;
    border-radius: 2px;
    padding: 2px;
    margin-top: 15px;
    position: absolute;
    z-index: 1000;
}

.FieldMetricsTableRowIndicator {
    width: 5px;
    height: 100%;
    display: inline-block;
    background: red;
    float: left;
    margin-right: 5px;
}

.FieldAttributesTableContainer {
    max-width: 600px;
    margin: auto;
    background: #f3f3f7;
    padding: 5px;
    border-radius: 5px;
    margin-top: 40px;
    border: 2px solid #0f1a2e;
}

.FieldAttributesTableLabel {
    text-align: center;
    font-weight: bold;
    font-size: 22px;
    text-decoration: underline;
    margin-top: 0px;
}

.FieldAttributesLabel {
    margin: 2px;
}

.RoundedDivider {
  border-top: 5px dotted #a5a5ad;
  margin: 10px;
  margin-top: 40px;
}
