.MetricsCollapsible {
/*    padding-top: 10px;*/
    max-width: 1600px;
    margin: auto;
}

.MetricsCollapsibleTrigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  color: white;
  position: relative;
  border: 1px solid #1c549a;
  box-shadow: 6px 4px 10px 1px rgb(0 0 0 / 80%);
  padding: 10px;
  background: #2b2b2b;
  max-width: 1600px;
  margin: 10px;
  border-radius: 5px;
  height: 80px;
  user-select: none;
  cursor: pointer;
}

.MetricsCollapsibleTrigger:after {
    font-family: 'FontAwesome';
    content: '\f107';
    position: absolute;
    right: 15px;
    top: 35px;
    display: block;
    font-size: 33px;
    transition: transform 300ms;
  }

.MetricsCollapsibleTrigger.is-open:after {
    transform: rotateZ(180deg);
}
  

.MetricsCollapsibleTrigger.is-disabled {
    opacity: 0.5;
    background-color: grey;
}


.MetricsCollapsibleContentInner {
    width: 100%;
    max-width: 1600px;
    display: inline-block;
}
.MetricsCollapsibleContentOuter {
    background: #e5e5ec;
    margin:10px;
    border-radius: 5px;
    box-shadow: 6px 4px 10px 1px rgb(0 0 0 / 80%);
}

.tippy-tooltip {
    padding: 0px!important;
    border: 3px solid #2b2b2b;
}
