/*.flexContainer {
    display: flex;
}

.flexChild {
    flex: 1;
    border: 2px solid yellow;
    margin:10px;
}  

.flexChild:first-child {
    margin-right: 20px;
}*/

.fadeIn {
    transition: opacity 200ms ease;
}
.fadeOut {
    opacity: 0;
    transition: opacity 100ms ease;
}


.RequestQueueContainer {
    max-width: 1600px;
    margin: auto;
    margin-top: 20px;
}
.RequestQueueMenuContainer {
    background: #e5e5ec;
/*    background: #f7f7f7;*/
/*    border: 2px solid #1C549A;*/
    border-radius: 5px;
    height: 550px;
    margin-top: 10px;
    box-shadow: 6px 4px 10px 1px rgb(0 0 0 / 80%);
}
.RequestQueueHeader {
    box-sizing: border-box;
/*    margin: 5px;*/
    height: 70px;
/*    line-height: 50px;*/
    text-align: left;
} 
.RequestQueueHeaderSwitchContainer {
    float: right;
    display: inline-block;
    margin-right: 10px;
}

.RequestQueueHeaderSwitchContainer p {
    display: inline-block;
    font-size: 16px;
    padding: 0;
    margin: 0;
    margin-right: 10px;
}

.RequestQueueHeaderTitle {
    display: inline-block;
    color: #0F1A2E;
    padding: 0;
    margin: 0;
    margin-left: 15px;
    font-size: 28px;
    line-height: 70px;
} 

.RequestQueueHeaderSelectContainer {
/*    height: 30px;*/
    margin-top: 5px;
    display: block;
}

.RequestQueueHeaderSelect {
}

.RequestQueueMenu {
    box-sizing: border-box;
    margin: 10px;
    background: #2b2b2b;
    height: 460px;
/*    margin: 10px;*/
/*    margin-top: 10px;*/
    border-radius: 5px;
} 

.RequestMenuDivider {
    color: #f7f7f7;
    width: 100%;
    border-top: 2px solid #1C549A;
    border-bottom: 2px solid #1C549A;
    margin-top: 10px;
    margin-bottom: 10px;
}
.RequestMenuSingleDivider {
    width: 100%;
    border-top: 1px solid #1C549A;
    border-bottom: 1px solid #1C549A;
    margin-top: 10px;
    margin-bottom: 10px;
}
.RequestMenuDivider p{
    font-size: 22px;
    padding: 0;
    margin:0;
}
.RequestMenuContainer {
    position: relative;
    height: 100%;
    box-sizing: border-box;
    padding: 20px;
}

.RequestMenuSelect{
    padding-top: 5px;
    padding-bottom: 5px;
}

.RequestQueueDateRangeContainer {
    display: flex;
}

.RequestQueueDatePickerContainer{
    flex: 1;
    color: #f7f7f7;
}  

.RequestQueueDatePickerContainer:first-child {
    margin-right: 20px;
} 

.RequestQueueDatePicker {
     text-align: center;
  height: 25px;
  border-radius: 5px;
  border: none;
/*  padding: 5px;*/
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;

}

.DatePickerWrapper {
  width: 100%;
}

.RequestQueueButtonContainer {
    width: 100%;
    position: absolute;
    bottom: 20px;
    left: 0px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
}

.RequestQueueButton {
  color: #0F1A2E;
  font-weight: bold;
  background: #00BD70;
  width: 100%;
  cursor: pointer;
  padding: 10px;
  border:none;
  border-radius: 5px;
/*  border: 2px solid #1C549A;*/
  font-size: 14px;
/*  margin-top: 30px;*/
  -webkit-transition: all 200ms;
  -moz-transition:    all 200ms;
  -ms-transition:     all 200ms;
  -o-transition:      all 200ms;
  transition:         all 200ms;
}
.RequestQueueButton:hover {
  background: #019257;
}
.RequestQueueButton:disabled {
  color: #757575;
  background: rgba(239, 239, 239, 0.3);
}

.QueueMenuContainer {
    position: relative;
    box-sizing: border-box;
    padding: 20px;
    height: 100%;
}

.QueueMenuItemContainer {
    height: 350px;
/*    overflow: auto;*/
}

.QueueMenuItem {
  background: #696969;
  color: #0F1A2E;
  border-radius: 5px;
  padding:5px;
/*  font-weight: bold;*/
  margin: 0px 10px 10px 10px;
  box-shadow: 5px 3px 8px 1px rgb(0 0 0 / 80%);
  height: 60px;
  border: 1px solid #1c549a;
}

.QueueMenuItem p {
  margin: 2px;
  margin-left: 20px;
  padding: 0px;
}

.QueueMenuItemLeftContainer {
    float: left;
}

.QueueMenuItemName {
  text-align: left;
  font-size: 22px;
  color: #00ff97;
}

.QueueMenuItemDate {
  text-align: left;
  color: #0F1A2E;
  font-weight: bold;
}

.QueueMenuItemRightContainer {
    float: right;
}

.QueueMenuItemEdit {
/*    float: left;*/
    display: inline-block;
    margin-left: 10px;
/*    margin-top: 5px;*/
    font-size: 16px;
    cursor: pointer;
    color: #0f1a2e;
}
.QueueMenuItemEdit:hover {
    color: #1C549A;
}


.QueueMenuItemRemove {
    display: inline-block;
    font-size: 24px;
    line-height: 60px;
    text-align: right;
    margin-right: 20px;
    font-family: 'FontAwesome';
    cursor: pointer;
    color: #0f1a2e;
}
.QueueMenuItemRemove:hover {
    color: #ff4c4c;
}

.thumbVertical {
    background-color: rgba(255, 255, 255, 0.6);
}