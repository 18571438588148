 .ModalOverlay{
  background: rgba(0,0,0,0.8);
}

.Modal{
  border-radius: 5px;
  padding: 40px;
  
}

.ModalCloseButton{
/*  top: -30px;
  right: -5px;
  */
}
.ModalCloseButton:hover{
  cursor: pointer;
}
.ModalCloseIcon{
  fill: #0D1B2A!important;
  width: 30px;
  stroke: white;
  stroke-width: 2px;
  width: 23px;
  height: 23px;
}
.ModalInput {
  height: 30px;
  width: calc(100% - 36px);
  border: 1px solid #ccc;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 2px rgba(0,0,0,.075);
  padding-bottom: 0px;
  padding-top: 0px;
  margin-top: 0px;
  margin-bottom: 0px;

}

.ModalButton {
  color: #fafafa;
  background: #00BD70;
  width: 100%;
  cursor: pointer;
  padding: 10px;
  border:none;
  border-radius: 5px;
  font-size: 14px;

  -webkit-transition: all 200ms;
  -moz-transition:    all 200ms;
  -ms-transition:     all 200ms;
  -o-transition:      all 200ms;
  transition:         all 200ms;
}
.ModalButton:hover {
  background: #019257;
}
.ModalButton:disabled {
  color: #8c8c8c;
  background: #40474d;
}

.DateTypeContainer {
  margin-top: 20px;
}

.DateRangeContainer {
  margin-top: 10px;
  margin-bottom: 20px;
    display: flex;
}

.DatePickerContainer{
    flex: 1;
    color: #f7f7f7;
}  

.DatePickerContainer:first-child {
    margin-right: 20px;
} 

.DatePicker {
     text-align: center;
  height: 25px;
  border-radius: 5px;
/*  border: none;*/
/*  padding: 5px;*/
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  border: 1px solid grey;
}

.DatePickerWrapper {
  width: 100%;
}
