.FloatingShareButtonContainer {
    position: fixed;
    width: 50px;
    height: 50px;
    bottom: 50px;
    float: right;
    right: 30px;
    z-index: 999;
    background: rgb(0 0 0 / 70%);
    border-radius: 100%;
    border: 2px solid #e5e5ec;
    box-shadow: 6px 4px 10px 1px rgb(0 0 0 / 80%);
    line-height: 55px;
    cursor: pointer;
}

.FloatingShareButtonContainer:hover {
    border: 2px solid #1C549A;
}

.FloatingShareButton {
    color: #f4f4f4;
    font-size: 24px;
}


.ModalOverlay{
  background: rgba(0,0,0,0.8);
}

.Modal{
  border-radius: 5px;
  padding: 40px;
  
}

.ModalCloseButton{
  top: -30px;
  right: -5px;
  
}
.ModalCloseButton:hover{
  cursor: pointer;
}
.ModalCloseIcon{
  fill: #0D1B2A!important;
  width: 30px;
  stroke: white;
  stroke-width: 2px;
  width: 23px;
  height: 23px;
}
.ModalInput {
  height: 30px;
  width: calc(100% - 36px);
  border: 1px solid #ccc;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 2px rgba(0,0,0,.075);
  padding-bottom: 0px;
  padding-top: 0px;
  margin-top: 0px;
  margin-bottom: 0px;

}

.ShareLinkButton {
  width: 30px;
  height: 32px;
  display: inline-block;
  float: right;
  padding: 0px;
  margin: 0px;
  color: #4e4e4e;
  border: none;
  outline: none;
  text-align: center;
  font-weight: 700;
  background-color: #eee;
  border: 1px solid #d5d5d5;
  cursor: pointer;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.ShareLinkButton:hover {
  background-color: #ddd;
}

.ModalButton {
  color: #fafafa;
  background: #00BD70;
  width: 100%;
  cursor: pointer;
  padding: 10px;
  border:none;
  border-radius: 5px;
  font-size: 14px;

  -webkit-transition: all 200ms;
  -moz-transition:    all 200ms;
  -ms-transition:     all 200ms;
  -o-transition:      all 200ms;
  transition:         all 200ms;
}
.ModalButton:hover {
  background: #019257;
}
.ModalButton:disabled {
  color: #8c8c8c;
  background: #40474d;
}

.ShareLinkContainer {
  height: 25px;
  position: relative;
}

