.MenuBarContainer {
	height: 90px;
	width: 100%;
	margin-bottom: 10px;
	background: #2b2b2b;
  background: linear-gradient(180deg, rgba(43,43,43,1) 0%, rgba(43,43,43,1) 20%, rgba(20,20,20,1) 100%);
	border-bottom: 2px solid #1C549A;
	box-shadow: 0px 2px 10px 0px rgb(0 0 0 / 80%);
	position: sticky;
	top: 0px;
	z-index: 9999;
}

.MenuBarUTCTimeContainer {
/*	height: 20px;
	width: 100%;
	
	background: #e5e5ec;
	border-bottom: 2px solid #1C549A;
	position: sticky;
	top: 0px;
	z-index: 9909;*/

	  background: #ffffff60;
    position: fixed;
    bottom: 0px;
    left: 0px;
    margin: 10px;
    border-radius: 3px;
    font-family: sans-serif;
}

.MenuBarUTCTimeContainer p {
	font-size: 14px;
    color: #0D1B2A;
    margin: 3px;
}

.MenuBarTopContainer {
	height: 50px;
	line-height: 50px;
	margin-left: 10px;
	margin-right: 10px;
}

.MenuBarBottomContainer {
	height: 40px;
	line-height: 40px;
	margin-left: 10px;
	margin-right: 10px;
}

.MenuBarLogoContainer {
	color: white;
	float: left;
	height: 50px;
/*	margin-right: 10px;*/
}

.MenuBarLogoImgContainer {
    display: flex;
    float: left;
    height: 100%;
    align-items: center;
}

.MenuBarLogo {
	width: 70px;
	float: left;
/*	margin-right: 5px;
	margin-left: 5px;*/
}

.MenuBarLogoHeader {
	color: white;
	font-size: 32px;
	margin: 0px;
/*	margin-left: 10px;*/
	float: left;
}

.MenuBarNavLinkContainer {
	float: left;
	cursor: pointer;
	margin-right: 10px;
	margin-left: 10px;
/*  padding-left: 5px;
  padding-right: 5px;*/
/*  background: #1c549a;*/
}

/*.MenuBarNavLinkContainer:hover {
	border-bottom: 2px solid #00BD70;
}*/

.MenuBarNavLink {
	font-size: 18px;
	color: #00BD70;
	text-decoration: none;
	padding-bottom: 7px;
	margin: 0px;
}

.MenuBarNavLinkActive {
	border-bottom: 2px solid #00BD70;
}


.MenuBarButton {
  float: right;
  color: #0F1A2E;
  font-weight: bold;
  background: #00BD70;
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border:none;
  border-radius: 5px;
/*  border: 2px solid #1C549A;*/
  font-size: 14px;
/*  margin-top: 30px;*/
  -webkit-transition: all 200ms;
  -moz-transition:    all 200ms;
  -ms-transition:     all 200ms;
  -o-transition:      all 200ms;
  transition:         all 200ms;
}
.MenuBarButton:hover {
  background: #019257;
}

.MenuBarButtonContainer {
	float: right;
    width: 180px;
}

.MenuBarUsernameText {
	font-size: 16px;
	color: white;
	float: right;
	margin: 0px;
}