 .FloatingFavoritesButtonContainer {
    position: fixed;
    width: 50px;
    height: 50px;
    bottom: 50px;
    float: right;
    right: 100px;
    z-index: 999;
    background: rgb(0 0 0 / 70%);
    border-radius: 100%;
    border: 2px solid #e5e5ec;
    box-shadow: 6px 4px 10px 1px rgb(0 0 0 / 80%);
    line-height: 55px;
    cursor: pointer;
}

.FloatingFavoritesButtonContainer:hover {
    border: 2px solid #1C549A;
}

.FloatingFavoritesButton {
    color: #f4f4f4;
    font-size: 24px;
}

