.Footer{
  background: #ffffff60;
  position: fixed;
  bottom: 0px;
  right: 0px;
  margin: 10px;
  border-radius: 3px;
  font-family: sans-serif;
}
.Footer p{
  font-size: 14px;
  color: #0D1B2A;
  margin: 3px;
}
.Footer a{
  text-decoration: none!important;
  cursor: pointer;
}
.Footer a:visited{
  color: #0D1B2A;
}
.Footer a:active{
  color: #0D1B2A;
}

.ModalOverlay{
  background: rgba(0,0,0,0.8);
}

.Modal{
  border-radius: 5px;
  padding: 40px;
  
}

.ModalCloseButton{
/*  top: -30px;
  right: -5px;*/
  
}
.ModalCloseButton:hover{
  cursor: pointer;
}
.ModalCloseIcon{
  fill: #0D1B2A!important;
  width: 30px;
  stroke: white;
  stroke-width: 2px;
  width: 23px;
  height: 23px;
}
.ModalInput {
  height: 25px;
  width: calc(100% - 15px);
  padding-left: 10px;
  /* border-radius: 4px; */
  border: 1px solid #ccc;
  border-radius: 3px;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 2px rgba(0,0,0,.075);

}

.ModalButton {
  color: #fafafa;
  background: #00BD70;
  width: 100%;
  cursor: pointer;
  padding: 10px;
  border:none;
  border-radius: 5px;
  font-size: 14px;

  -webkit-transition: all 200ms;
  -moz-transition:    all 200ms;
  -ms-transition:     all 200ms;
  -o-transition:      all 200ms;
  transition:         all 200ms;
}
.ModalButton:hover {
  background: #019257;
}
.ModalButton:disabled {
  color: #8c8c8c;
  background: #40474d;
}

