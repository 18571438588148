.UserDashboardContainer {
    max-width: 1600px;
    margin: auto;
    margin-top: 30px;
    /*color: #f4f4f4;*/
    color: #0F1A2E;
}

.UserDashboardBackground {
    background: #2b2b2b;
/*    background: #e5e5ec;*/
    margin: 16px;
    border-radius: 5px;
    border: 1px solid #1c549a;
    box-shadow: 6px 4px 10px 1px rgb(0 0 0 / 80%);
}

.UserDashboardTitleContainer {
    text-align: left;
    margin-left: 20px;
}

.UserDashboardTitleContainer p {
    font-size: 32px;
    color:  #f4f4f4;
    display: inline-block;
}

.AddFavoriteButtonContainer {
    display: inline-block;
    margin-left: 20px;
    position: relative;
    bottom: 5px;

}

.AddFavoriteButton {
 color: #0F1A2E;
  font-weight: bold;
  background: #00BD70;
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border:none;
  border-radius: 5px;
/*  border: 2px solid #1C549A;*/
  font-size: 14px;
/*  margin-top: 30px;*/
  -webkit-transition: all 200ms;
  -moz-transition:    all 200ms;
  -ms-transition:     all 200ms;
  -o-transition:      all 200ms;
  transition:         all 200ms;
}
.MenuBarButton:hover {
  background: #019257;
}

.UserFavoriteContainer {
    display: flex;
    background: #696969;
    color: #00ff97;
    text-align: left;
    margin-left: 20px;
    margin-right: 20px;
/*    margin-top: 10px;*/
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 5px;
/*    height: 30px;
    line-height: 30px;*/
    border: 1px solid #1c549a;
    box-shadow: 5px 3px 8px 1px rgb(0 0 0 / 80%);
/*    cursor: pointer;*/
    text-decoration: none;
}

.UserFavoriteLeftContainer {
    cursor: pointer;
    display: inline-block;
    width: calc(100% - 50px);
}

.UserFavoriteContainer p{
    margin: 0px;
}

.UserFavoriteLabel {
    display: inline-block;
    font-size: 22px;
    font-weight: bold;
    text-decoration: underline;
    color: #0f1a2e;
}

.UserFavoriteValue {
    display: inline-block;
    margin-left: 10px!important;
    font-size: 16px;
    color: #00ff97;
}

.UserFavoriteDate {
    display: inline-block;
    margin-left: 10px!important;
    color: #0F1A2E;
}

.UserFavoriteUserContainer {
    margin-top: 10px;
}

.UserFavoriteUserContainer .UserFavoriteLabel {
    font-size: 16px;
    text-decoration: none;
    font-weight: normal;
}

.UserFavoriteUser {
    display: inline-block;
    margin-left: 5px!important;
    color: #0F1A2E;
}

.UserFavoriteDeleteContainer {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2b2b2b;
    font-size: 30px;
    cursor: pointer;
}

.UserFavoriteDelete:hover {
    color: #b31c1c;
}

.UserFavoriteDelete {
    cursor: pointer;
}

.AnalystAssignmentCompletionContainer {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
}

.AnalystAssignmentCompletionCircleContainer {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #2b2b2b;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    box-shadow: 5px 3px 8px 1px rgb(0 0 0 / 80%);
}
.AssignmentCompleteIcon{

}

.AssignmentIncomplete {
    font-size: 20px;
    text-decoration: none!important;
    color: #b31c1c;
}