.modalBgOverlay {
  position: absolute;
  background: rgba(0,0,0,.8);
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.modalLoginContainer {
  border-radius: 5px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 200px;
  height: 250px;
  background: #ffffff;
  box-shadow: 12px 0px 15px 0 rgba(0, 0, 0, 0.25);
}

.modalLoginHeader p {
  color: #0d1b2a;
  font-size: 18px;
}

.modalLoginBody{
  text-align: left;
  margin: 10px;
/*  padding-top: 10px;*/
}

.modalLoginBody p{
  color: #0d1b2a;
  font-size: 14px;
  margin:0px;
}
.modalLoginBody input{
  font-size: 12px;
  width: 175px;
  margin-top: 10px;
  border: none;
  background: #eaeaea;
  height: 30px;
  border-radius: 3px;
  padding-left: 5px;
}
.modalLoginBody input:hover {
  background: #e4e4e4;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}
.modalLoginBody input:focus {
  outline:none;
}
.modalLoginBody a {
  font-size: 10px;
}

.modalLoginButton {
  outline: none;
  margin-top: 30px;
  color: #ffffff;
  background: #00bd70;
  width: 100%;
  cursor: pointer;
  padding: 5px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
}
.modalLoginButton:hover {
  background: #019257;
  -webkit-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;
}
.modalLoginButton:disabled {
  background: gray;
}

.modalLoginSpinner {
  -webkit-animation: fa-spin 1.5s infinite linear;
  animation: fa-spin 1.5s infinite linear;
  height: 16px;
}

@media only screen and (min-width: 768px) {
  .modalLoginContainer {
    width: 300px;
    height: 280px;
  }
  .modalLoginHeader p{
    font-size: 22px;
  }
  .modalLoginBody input{
    font-size: 14px;
    width: 275px;
    margin-top: 10px;
    height: 35px;
    border-radius: 3px;
    padding-left: 5px;
  }
  .modalLoginButton {
    margin-top: 30px;
    padding: 10px;
    font-size: 16px;
  }
}
