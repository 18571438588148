.FileListExpandIconContainer {
    display: inline-block;
    float: right;
}

.FileListExpandIcon {
}

.MetricsContainer {
    margin: 20px 20px 20px 20px;
}

.MetricsCollapsibleTriggerContainer {

}


.MetricsCollapsibleTriggerLabelContainer {
    float: left;
    margin-left: 20px;
    margin-top: 6px;
    text-align: left;
}

.MetricsCollapsibleTriggerLabel {
    margin: 0px;
    color: #f4f4f4;
    font-size: 28px;
}

.MetricsCollapsibleTriggerDateLabel {
    margin: 0px;
    font-size: 16px;
    color: #989898;
}


.MetricsCollapsibleTriggerLinksContainer {
    float: right;
    margin-right: 60px;
    margin-top: -102px;
    padding: px;
    display: inline-block;
    height: 70px;
    line-height: 12px;
    position: relative;
    border: 1px solid #1C549A;
    padding: 5px;
    border-radius: 5px;
    background: #0f1a2e;
    color: #f4f4f4;
    width: 100px;
}

.MetricsCollapsibleTriggerLinksContainer a {
    color: #00bd70;
}

.MetricsVersionContainer {
    text-align: left;
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    float: right;
}

.MetricsVersionContainer p {
    font-size: 14px;
    padding: 0px;
    margin: 0px;
}

.PlotsLink {
    font-size: 22px!important;
    cursor: pointer;
/*    text-decoration: underline;*/
    text-align: left;
    margin-bottom: 5px!important;
    padding: 3px 6px 3px 6px!important;
    background: #2b2b2b;
    border-radius: 4px;
    color: #e5e5ec;
    box-shadow: 0px 2px 5px 0px rgb(0 0 0 / 80%);
}

.PlotsLink:hover {
    background: #141414;
}

.PlotsIcon{
    text-align: right;
    float: right;
    margin-top: 5px;
}

.MetricsDateContainer{
    text-align: left;
    margin-left: 20px;
    margin-top: 20px;
    border-left: 10px solid #00BD70;
    float: left;
}

.MissingMetricsDateContainer {
    border-left: 10px solid #ff7777!important;
}

.MissingMetricsAlertIcon {
    font-size: 22px;
    color: #ff7777;
    margin-left: 10px;
    cursor: pointer;
}

.MissingMetricsAlertIcon:hover {
    color: #dd5d5d;
}


.MissingMetricsTextContainer {
    display: inline-block;
}

.MissingMetricsTextContainer p {
    font-size: 28px;
    color: #ff7777;
}

.MissingMetricsAlertButtonContainer, .ReprocessButtonContainer {
    float: right;
    margin-right: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.MissingMetricsAlertButton, .ReprocessButton {
    font-weight: bold;
/*    color: #e5e5ec;*/
    background: #ff7777;
    cursor: pointer;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border: none;
    border-radius: 5px;
    /* border: 2px solid #1C549A; */
    font-size: 14px;
    /* margin-top: 30px; */
    transition: all 200ms;
    box-shadow: 0px 2px 5px 0px rgb(0 0 0 / 80%);
}

.MissingMetricsAlertButton:hover, .ReprocessButton:hover {
    background: #dd5d5d;
}

.ReprocessDataTable {
    width: 100%;
}

.ModalOverlay{
  background: rgba(0,0,0,0.8);
}

.Modal{
  border-radius: 5px;
  padding: 40px;
  
}

.ModalCloseButton{
/*  top: -30px;
  right: -5px;*/
  
}
.ModalCloseButton:hover{
  cursor: pointer;
}
.ModalCloseIcon{
  fill: #0D1B2A!important;
  width: 30px;
  stroke: white;
  stroke-width: 2px;
  width: 23px;
  height: 23px;
}
.ModalInput {
  height: 25px;
  width: calc(100% - 15px);
  padding-left: 10px;
  /* border-radius: 4px; */
  border: 1px solid #ccc;
  border-radius: 3px;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 2px rgba(0,0,0,.075);

}

.ModalButton {
  color: #fafafa;
  background: #00BD70;
  width: 100%;
  cursor: pointer;
  padding: 10px;
  border:none;
  border-radius: 5px;
  font-size: 14px;

  -webkit-transition: all 200ms;
  -moz-transition:    all 200ms;
  -ms-transition:     all 200ms;
  -o-transition:      all 200ms;
  transition:         all 200ms;
}
.ModalButton:hover {
  background: #019257;
}
.ModalButton:disabled {
  color: #8c8c8c;
  background: #40474d;
}


.MetricsDateContainer p {
    padding: 0px;
    margin: 0px;
    font-size: 36px;
    font-weight: bold;
    padding-left: 10px;
    color:  #2b2b2b;
    display: inline-block;
}

.MetricsTableHeaderLabel {
    border: 1px solid black;
}

.MetricsTableHeaderLabelCell {
    border: 1px solid black;
}

.MetricsTableCell {

}

.MetricsTableCellDiv {
    height: 100%;
    width: 100%;
    cursor: pointer;
}

.MetricsTableCellDiv p {
    padding: 0px;
    margin: 0px;
    visibility: hidden;
}
.MetricsTableCellDiv:hover p {
    visibility: visible;
}

.MetricsTableCellTooltip {
    display: block!important;
    height: 100%;
}

.MetricsTableCellTooltipDiv {
    padding: 8px;
    width: 300px;
    font-family: Merriweather Sans,sans-serif;
}

.MetricsTableCellTooltipDiv p {
    margin: 0px;
    padding: 0px;
    color: black;
    display: inline-block;
    font-size: 16px;
}

.MetricsTableCellTooltipDescription {
    text-align: left;
    color: black;
    margin-left: 4px;
    margin-right: 4px;
}

.MetricsTableCellTooltipDescription p {
    font-size: 14px;
}


.MetricsTableCellTooltipAssessmentText {
    width: 50%;
    text-align: left;
    font-weight: bold;
}

.MetricsTableCellTooltipPercentText {
    width: 50%;
   text-align: right;
   font-weight: bold;
}

.MetricsTableContainer {
    margin: 20px;
    margin-top: 0px;
}

.MetricsTable {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    padding: 10px;
}

.MetricsTable td {
  height: 10px;
  border-left: 1px dotted #2b2b2b;
/*  padding: 5px;*/
}

.MetricsTable thead  {
    border-right: 1px solid black;

}

.MetricsTable thead th {
/*    border-bottom: 1px solid #2b2b2b;*/
    background: #e5e5ec;
}

.MetricsTable tr {
/*    border-bottom: 1px solid #2b2b2b;*/
    background: #f3f3f7;
}

.MetricsTable tr:hover {
/*    border-bottom: 1px solid #2b2b2b;*/
    background-color: #aaffaa!important;
    background: #aaffaa!important;
}

.MetricsTable tbody {
    border: 2px solid #2b2b2b;
}

.MetricsTable td:nth-child(n+2) {
  border-bottom: 1px dotted #2b2b2b;
  padding: 0px;
}

.MetricsTable td:nth-child(1) {
  border-bottom: 1px solid #2b2b2b;
}

.MetricsTableRowLabel {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    font-family: Merriweather Sans,sans-serif;
    border-right: 2px solid #2b2b2b;
    font-size: 12px;
    cursor: pointer;
}

.MetricsTableRowLabelInnerDiv {
    padding: 5px;
}


.RoundedDivider {
  border-top: 5px dotted #a5a5ad;
  margin: 10px;
  margin-top: 40px;
}
